import posthog from 'posthog-js';
import './App.css';


function App() {
  const handleLinkClick = (linkName) => {
    posthog.capture('link_clicked', { link: linkName });
  };
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <code>Hi! This is Atharva Gadekar</code>
        </p>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/atharvagadekar/" target="_blank" rel="noopener noreferrer"  onClick={() => handleLinkClick('LinkedIn')}>
            LinkedIn
          </a>
          <span>|</span>
          <a href="https://x.com/Atharva5416" target="_blank" rel="noopener noreferrer"  onClick={() => handleLinkClick('X')}>
            X
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
